import React from 'react';

export default function FlagDE() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
        <path fill="#C4C4C4" d="M0 4h24v16H0z" />
      </mask>
      <g mask="url(#a)">
        <path d="M24.102 2.557H.048v18.888h24.054V2.557Z" fill="#fdcd01" />
        <path d="M24.102 2.557H.048v12.591h24.054V2.558Z" fill="#d00" />
        <path d="M24.102 2.557H.048v6.297h24.054V2.557Z" fill="#000000" />
      </g>
    </svg>
  );
}
