import $http from 'services/http.service';
import { clientId, codeChallenge, codeChallengeMethod, redirectUri, responseType } from 'utils/helper.utils';

export const login = async ({ email, password, keepLoggedIn }: LoginEntry, requestOptions = {}) => {
  return $http({
    method: 'POST',
    url: `/login`,
    withCredentials: true,
    data: {
      email,
      password,
      keepLoggedIn
    },
    headers: {
      'client-id': clientId,
      'redirect-uri': redirectUri,
      ...(responseType ? { 'response-type': responseType } : {}),
      ...(codeChallenge ? { 'code-challenge': codeChallenge } : {}),
      ...(codeChallengeMethod ? { 'code-challenge-method': codeChallengeMethod } : {})
    },
    ...requestOptions
  });
};

export const authorize = (authCode: string) =>
  $http({
    method: 'POST',
    url: `/authorize`,
    data: {
      authCode
    },
    headers: {
      'client-id': clientId,
      'redirect-uri': redirectUri,
      ...(responseType ? { 'response-type': responseType } : {}),
      ...(codeChallenge ? { 'code-challenge': codeChallenge } : {}),
      ...(codeChallengeMethod ? { 'code-challenge-method': codeChallengeMethod } : {})
    }
  });
