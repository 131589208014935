import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logout } from 'services/logout.service';
import { Text } from '../components/Text/Text.component';
import { useTranslation } from 'react-i18next';
import Popup from 'components/Popup/Popup.component';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Text size="s" className="text-red my-xs" data-cy={'logout-error'}>
      {t('somethingWrong')}
    </Text>
  );
};

const Logout = () => {
  const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState('loader');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function logoutUser() {
      try {
        const response = await logout();

        searchParams.set('auto_login', 'false');

        if (response.status === 200) navigate(`/?${searchParams.toString()}`);
      } catch (error) {
        console.error(error);
        setError(true);
        setShowPopup('');
      }
    }
    logoutUser();
  }, []);

  return (
    <>
      {error && <ErrorMessage />}
      {showPopup && <Popup data-cy={showPopup} type={showPopup} />}
    </>
  );
};

export default Logout;
