import React, { DetailedHTMLProps, InputHTMLAttributes, LegacyRef, useEffect, useState } from 'react';
import { Text } from 'components/Text/Text.component';
import { Icon } from 'components/Icons/Icons.component';
import { useForm, ControllerFieldState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { camelToKebab } from 'utils/helper.utils';

interface TextFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  defaultValue?: string;
  value: string;
  placeholder?: string;
  autoFocus?: boolean;
  name?: string;
  type?: 'email' | 'password' | 'text';
  className?: string;
  label?: string;
  fieldState?: ControllerFieldState;
  showHint?: boolean;
  showError?: boolean;
}

type InputAnimationEvent = React.AnimationEvent<HTMLInputElement>;

const Label = ({ label, isInputActive }: { label?: string; isInputActive: boolean }) => {
  return (
    <span
      className={`lbf-text-field__label-text font-heading-double absolute left-[16px] transition-all linear duration-300
      ${isInputActive ? 'text-[12px] leading-[20px] top-[8px]' : 'text-[16px] leading-[28px] top-1/2 -translate-y-1/2'}
    `}
    >
      {label}
    </span>
  );
};

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      autoComplete,
      label,
      value,
      type = 'text',
      defaultValue,
      fieldState,
      name,
      showHint = true,
      showError = true,
      onBlur,
      onFocus,
      ...rest
    }: TextFieldProps,
    ref
  ) => {
    const [isInputActive, setIsInputActive] = useState(!!value);

    // Using useState since react-hook-form doesn't provide a way to get the current state of the form
    const [isFocused, setIsFocused] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [inputType, setInputType] = useState(type);

    const { trigger } = useForm();

    const { t } = useTranslation();

    useEffect(() => {
      if (value !== defaultValue && !fieldState?.isTouched) {
        trigger(name, { shouldFocus: true });
      }
    }, [defaultValue, value, fieldState?.isTouched, trigger]);

    useEffect(() => {
      setIsInputActive(!!value);
    }, [value]);

    useEffect(() => {
      if (type === 'password') {
        setInputType(showPassword ? 'text' : 'password');
      }
    }, [showPassword, type]);

    const onAnimationStart = (event: InputAnimationEvent) => {
      if (event.animationName === 'onAutoFillStart') {
        setIsInputActive(true);
      }
    };

    const showOnlyHint = showHint && !showError;
    const showOnlyError = !showHint && showError;
    const showErrorMsg = (showHint && showError) || (showOnlyHint && isFocused) || (showOnlyError && !isFocused);

    return (
      <div className={`lbf-text-field ${className}`}>
        <label className="relative block lbf-text-field__label">
          {/* Status border */}
          <span
            className={`lbf-text-field__status-border h-full w-[2px] bg-transparent absolute left-0
            ${isFocused ? 'bg-orange' : ''}
            ${fieldState?.error && !isFocused ? 'bg-red' : ''}
          `}
          ></span>

          <input
            ref={ref as LegacyRef<HTMLInputElement>}
            name={name}
            type={inputType}
            autoComplete={autoComplete}
            value={value}
            className={`
            lbf-text-field__input-field w-full font-heading-treble font-bold text-[16px] rounded-none leading-[18px] bg-jersey-grey
              focus:outline-none px-[16px]
              ${isInputActive ? 'pb-[8px] pt-[30px]' : 'py-[19px]'}
              ${fieldState?.error && !isFocused ? 'bg-light-red' : ''}
            `}
            {...rest}
            onAnimationStart={onAnimationStart}
            onFocus={(e) => (onFocus && onFocus(e), setIsFocused(true))}
            onBlur={(e) => (onBlur && onBlur(e), setIsFocused(false))}
          />

          <Label isInputActive={isInputActive} label={label} />

          {type === 'password' && value && (
            <>
              <span className="absolute right-[16px] top-1/2 -translate-y-1/2" onClick={() => setShowPassword((oldValue: boolean) => !oldValue)}>
                {<Icon id={showPassword ? 'closed-eye' : 'open-eye'} className={`w-[25px] h-[18px] inline scale-100 overflow-hidden`} />}
              </span>
            </>
          )}
        </label>
        {showErrorMsg && fieldState?.error?.message && (
          <Text size="s" className={`${isFocused ? 'text-anthracite' : 'text-red'} my-xxs`} data-cy={camelToKebab(`${name}-error`)}>
            {t(fieldState?.error.message as string)}
          </Text>
        )}
      </div>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
