import React from 'react';

type HeadingProps = {
  className?: string;
  children: React.ReactNode;
  size: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs';
};

enum HeadingSizes {
  xl = 'h1', // desk = 48/50 - mob = 32/36
  l = 'h2', // desk = 32/36 - mob = 20/24
  m = 'h3', // desk = 24/28 - mob = 20/24
  s = 'h4', // desk = 20/24
  xs = 'h5', // desk = 16/28
  xxs = 'h6' // desk = 14/24
}

/**
 * Tailwind doesn't support dynamic classnames
 * Create a helper function that returns the class as whole string
 * https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
const getClass = (size: string) => {
  switch (size) {
    case 'xl':
      return 'text-heading-l sm:text-heading-xl font-extrabold';
    case 'l':
      return 'text-heading-s sm:text-heading-l font-extrabold';
    case 'm':
      return 'text-heading-s sm:text-heading-m font-extrabold';
    case 's':
      return 'text-heading-s font-extrabold';
    case 'xs':
      return 'text-heading-xs font-bold';
    case 'xxs':
      return 'text-heading-xxs font-bold';
  }
};

export const Heading = ({ className = '', children, size }: HeadingProps) => {
  return React.createElement(
    HeadingSizes[size],
    {
      className: `${getClass(size)} font-heading-treble m-0 uppercase ${className}`
    },
    children
  );
};
