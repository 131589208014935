import React from 'react';

export default function CloseIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="-1" x2="23.6963" y2="-1" transform="matrix(-0.697925 0.716171 -0.697925 -0.716171 19.5382 3)" stroke="#313136" strokeWidth="2" />
      <line y1="-1" x2="23.6963" y2="-1" transform="matrix(-0.697925 -0.716171 0.697925 -0.716171 21 20)" stroke="#313136" strokeWidth="2" />
    </svg>
  );
}
