import Cookies from 'js-cookie';
import { env } from 'utils/helper.utils';

const RESET_PASSWORD_COOKIE_NAME = 'reset_email';

export function setResetPasswordCookieForEmail(email: string): void {
  try {
    const previous = Cookies.get(RESET_PASSWORD_COOKIE_NAME);
    const value = previous ? JSON.parse(previous) : {};

    const timeoutMs = Number(env.REACT_APP_RESET_PASSWORD_TIMEOUT_MINUTES || 10) * 60 * 1000;
    const expires = Date.now() + timeoutMs;

    Object.assign(value, { [email]: expires });

    Cookies.set(RESET_PASSWORD_COOKIE_NAME, JSON.stringify(value), { expires: new Date(expires) });
  } catch (_ignored) {
    // Setting the cookie is not critical, so we can ignore any errors
  }
}

export function getResetPasswordTimeout(email: string): number {
  try {
    const cookie = Cookies.get(RESET_PASSWORD_COOKIE_NAME);

    if (!cookie) return 0;

    const parsedCookie = JSON.parse(cookie);
    return Number(parsedCookie[email] ?? 0);
  } catch {
    return 0;
  }
}
