import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Icon } from 'components/Icons/Icons.component';

type ModalProps = {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  disableClose?: boolean;
  onModalClose?: () => void;
};

ReactModal.setAppElement('#root');

export const Modal = ({ className = '', children, isOpen, onModalClose, disableClose = false }: ModalProps) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(isOpen);
  }, [isOpen]);

  const handleModalClose = () => {
    if (disableClose) return;
    onModalClose?.();
    setActive(false);
  };

  return (
    <ReactModal
      overlayClassName="fixed bg-black/[.7] inset-0 z-[1000]"
      className={`bg-white w-11/12 max-w-[650px] max-h-screen h-fit absolute m-auto inset-0 flex flex-col ${className}`}
      isOpen={active}
      onRequestClose={handleModalClose}
      testId="modal"
    >
      {!disableClose && (
        <div className="flex justify-end p-l" data-cy="modal-close">
          <a onClick={handleModalClose} className="cursor-pointer">
            <Icon id="close" />
          </a>
        </div>
      )}
      <div className="overflow-auto px-s pb-s sm:px-xxl sm:pb-xxl">{children}</div>
    </ReactModal>
  );
};
