import $http from 'services/http.service';
import { clientId, redirectUri } from 'utils/helper.utils';

export const validateClientAccess = async () =>
  $http({
    method: 'GET',
    url: `/validate-client-access`,
    headers: {
      'client-id': clientId,
      'redirect-uri': redirectUri
    }
  });
