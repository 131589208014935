import React from 'react';

const ClosedEyeIcon = () => (
  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.88794 17.0862L19.9742 0L20.8879 0.913759L3.8017 18L2.88794 17.0862Z" fill="#2D2D2D" />
    <path
      d="M1.74462 9.71047C2.48756 9.1291 3.77986 8.15991 5.26576 7.19071C8.26997 5.25232 10.6606 4.21869 12.2112 4.21869C13.2126 4.21869 14.6018 4.63872 16.2493 5.47857L17.2185 4.50937C15.1833 3.44332 13.5358 2.92639 12.2112 2.92639C10.3698 2.92639 7.78557 3.99244 4.52275 6.1246C2.16457 7.67517 0.323098 9.19348 0.226242 9.25815C0.0646729 9.38727 0 9.58129 0 9.77507C0 9.96886 0.0968964 10.1627 0.258465 10.2595C0.323138 10.3242 2.16438 11.681 4.55498 13.1024C5.49169 13.6516 6.36397 14.1363 7.17159 14.5239L8.14078 13.5547C5.58884 12.327 2.93998 10.5502 1.74448 9.71037L1.74462 9.71047Z"
      fill="#2D2D2D"
    />
    <path
      d="M24.2932 9.22579C24.2285 9.16112 22.3548 7.64281 19.9644 6.09224C19.4153 5.73688 18.8984 5.41373 18.4139 5.12305L17.4447 6.05976C18.026 6.3829 18.6076 6.73828 19.2537 7.15806C20.772 8.12726 22.0641 9.09645 22.8395 9.67782C20.9011 11.0346 15.4094 14.6526 12.2436 14.6526C11.436 14.6526 10.4668 14.4264 9.46537 14.0388L8.46399 15.0402C9.95012 15.654 11.21 15.9447 12.2114 15.9447C14.0529 15.9447 16.637 14.9755 19.9322 13.0696C22.3228 11.6806 24.1965 10.2914 24.2609 10.2267C24.4225 10.0976 24.5194 9.93602 24.5194 9.70978C24.5192 9.54867 24.4547 9.35488 24.2932 9.22576L24.2932 9.22579Z"
      fill="#2D2D2D"
    />
    <path
      d="M16.1525 9.41964C16.1525 8.8058 16.0234 8.25668 15.7649 7.73975L10.5961 12.9086C11.113 13.1671 11.6621 13.2962 12.276 13.2962C14.4081 13.2962 16.1525 11.5516 16.1525 9.41969L16.1525 9.41964Z"
      fill="#2D2D2D"
    />
    <path
      d="M8.39941 9.41971C8.39941 10.4858 8.81944 11.455 9.53019 12.1657L15.0219 6.67399C14.3112 5.96329 13.342 5.54321 12.2759 5.54321C10.1437 5.54299 8.39941 7.28755 8.39941 9.41971Z"
      fill="#2D2D2D"
    />
  </svg>
);

export default ClosedEyeIcon;
