import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Text } from 'components/Text/Text.component';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/Icons/Icons.component';

interface PopupProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
  type?: string;
}

const Popup = ({ text, className = '', type = 'loader', ...rest }: PopupProps) => {
  const [showPopup, setShowPopup] = useState(type !== 'loader');

  const { t } = useTranslation();

  useEffect(() => {
    if (type === 'loader') setTimeout(setShowPopup, 300, true);
  }, [type]);

  return (
    <>
      {showPopup && (
        <>
          <div className={`lbf-popup fixed left-0 top-0 bottom-0 right-0 z-10 bg-black/75 ${className}`} {...rest}>
            <div
              className={`lbf-popup__content absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden
          flex flex-col justify-center items-center min-h-[175px] min-w-[240px] bg-white rounded
        `}
            >
              {type === 'loader' && <img src={process.env.PUBLIC_URL + '/img/loader.png'} alt="Loading..." className="w-[200px]" />}
              {type === 'success' && <Icon id="thumbs-up" className={`w-[60px] h-[4em] scale-100`} />}
              <Text size="m" className={`mt-xxs ${type !== 'loader' ? '' : 'absolute -z-[1]'}`}>
                {type !== 'loader' ? text || t('success') : t('loading')}
              </Text>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Popup;
