import React, { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { Button } from 'components/Buttons/Button.component';
import { LanguageLabelProps, OptionWithLanguage, SelectedWithLanguage } from 'components/SelectElement/SelectComponents.component';
import { SelectElement } from 'components/SelectElement/SelectElement.component';
import { Heading } from 'components/Heading/Heading.component';
import { Modal } from 'components/Modals/Modal.component';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'services/i18n.service';

const defaultOption = {
  value: 'en-nl',
  label: 'English',
  language: 'en'
};

const languageOptions = [
  {
    value: 'nl-nl',
    label: 'Nederlands',
    language: 'nl'
  },
  {
    value: 'fr-fr',
    label: 'Français',
    language: 'fr'
  },
  {
    value: 'es-es',
    label: 'Español',
    language: 'es'
  },
  {
    value: 'de-de',
    label: 'German',
    language: 'de'
  },
  defaultOption
].sort((languageA, languageB) => {
  const labelA = languageA.label.toUpperCase();
  const labelB = languageB.label.toUpperCase();
  return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
});

type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const LanguageSwitch = ({ isOpen, onClose }: ModalProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [defaultValue, setDefaultValue] = useState(defaultOption);
  const [modalIsOpen, setModalOpenStatus] = useState(false);
  const { t, i18n } = useTranslation();

  const changedLanguage = (option: SingleValue<LanguageLabelProps> | MultiValue<LanguageLabelProps>) => {
    setSelectedLanguage((option as LanguageLabelProps).value);
  };

  useEffect(() => {
    setDefaultValue(languageOptions.find(({ language }) => language === i18n.language) || defaultOption);
  }, [i18n.language]);

  useEffect(() => {
    setModalOpenStatus(isOpen);
    if (!isOpen) {
      setSelectedLanguage('');
    }
  }, [isOpen]);

  const submit = async () => {
    await changeLanguage(selectedLanguage);
    setModalOpenStatus(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal isOpen={modalIsOpen} onModalClose={onClose}>
      <Heading size="m">
        <span data-cy="language-selector-heading">{t('selectLanguage')}</span>
      </Heading>

      <SelectElement
        className="mt-m mb-xxs"
        defaultValue={defaultValue}
        options={languageOptions}
        components={{ SingleValue: SelectedWithLanguage, Option: OptionWithLanguage }}
        isSearchable={false}
        menuPosition={'fixed'}
        onChange={changedLanguage}
      />

      <Button className="mt-m !w-full" disabled={!selectedLanguage} onClick={submit} data-cy="language-selector-confirm-button">
        {t('confirm')}
      </Button>
    </Modal>
  );
};
