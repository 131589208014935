import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './ErrorBoundary';
import App from './App';
import { init } from 'services/i18n.service';
import { pushCustomEvents } from 'services/analytics.service';

init()
  .then(() => {
    const rootContainer = document.getElementById('root');

    const root = createRoot(rootContainer as HTMLElement);

    root.render(
      <StrictMode>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </StrictMode>
    );
  })
  .catch(() => {
    pushCustomEvents({
      eventCategory: 'failure',
      eventAction: 'app-initialization',
      eventLabel: 'sso'
    });
  });
