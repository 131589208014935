import $http from 'services/http.service';
import { clientId } from 'utils/helper.utils';

export const logout = async () =>
  $http({
    method: 'POST',
    url: `/logout`,
    withCredentials: true,
    data: new URLSearchParams({ client_id: clientId || '' }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
