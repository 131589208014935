import axios from 'axios';
import { env, bfaTraceId } from 'utils/helper.utils';

const $http = axios.create();

$http.defaults.baseURL = env.REACT_APP_SERVER_BASE_URL as string;
$http.defaults.timeout = 30000;
$http.defaults.headers.post['Content-Type'] = 'application/json';
$http.defaults.withCredentials = true;
$http.defaults.headers.common['bfa-trace-id'] = bfaTraceId;

export default $http;
