import React, { HTMLAttributes, useEffect } from 'react';
import { Header } from 'components/Header/Header.component';
import { useLocation } from 'react-router-dom';
import { routes } from 'App';
import { pushPageView } from 'services/analytics.service';
import { Footer } from '../components/Footer/Footer.component';
import { useTranslation } from 'react-i18next';

type LayoutProps = HTMLAttributes<HTMLDivElement> & { bannerText?: string };
const Layout = ({ children = null, className = '', bannerText, ...rest }: LayoutProps): JSX.Element => {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    const { title } = routes.find(({ path }) => path === pathname) || { title: 'Login' };
    document.title = `Basic-Fit - ${title}`;
    pushPageView({ title, pathname });
  }, []);

  return (
    <>
      <main className={`pb-xxl ${className}`} {...rest}>
        <Header>
          <span data-cy="page-heading">{bannerText || `Basic-Fit ${t('login')}`}</span>
        </Header>
        <section className="relative container w-full">
          <div className="bg-white py-m px-s sm:py-l sm:px-m mt-[-48px] sm:mt-[-84px] sm:pb-20">{children}</div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
