import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type IButton = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: keyof typeof buttonColors;
};

const buttonColors: Record<string, string> = {
  purple: 'bg-primary hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-active disabled:bg-disabled ',
  orange: 'bg-orange hover:bg-orange-hover focus:bg-primary-hover active:bg-orange-active disabled:bg-disabled'
} as const;

export const Button = ({ children, className, color = 'purple', ...rest }: IButton): JSX.Element => {
  return (
    <button
      className={twMerge(
        `lbf-button
        flex items-center justify-center text-[1rem] text-center
        w-[250px] h-[56px] font-bold font-heading-treble
        transition-all duration-300 uppercase text-[white]
        focus:outline-mint focus:outline focus:outline-2
      `,
        buttonColors[color],
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};
