import $http from 'services/http.service';
import {
  clientId,
  codeChallenge,
  codeChallengeMethod,
  loginWithAppleCode,
  loginWithAppleIdToken,
  redirectUri,
  responseType,
  safeJsonParse,
  socialLoginProvider
} from 'utils/helper.utils';

export const loginWithApple = async () =>
  $http({
    method: 'POST',
    url: `/login`,
    withCredentials: true,
    headers: {
      'client-id': clientId,
      'redirect-uri': redirectUri,
      ...(responseType ? { 'response-type': responseType } : {}),
      ...(codeChallenge ? { 'code-challenge': codeChallenge } : {}),
      ...(codeChallengeMethod ? { 'code-challenge-method': codeChallengeMethod } : {})
    },
    data: {
      code: loginWithAppleCode,
      email: safeJsonParse(window.atob(loginWithAppleIdToken.split('.')[1]) ?? '{}')?.email,
      provider: socialLoginProvider
    }
  });
