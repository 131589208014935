import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'components/Modals/Modal.component';
import TextField from 'components/TextField/TextField.component';
import { Button } from 'components/Buttons/Button.component';
import { Link, useSearchParams } from 'react-router-dom';
import { authorize, login } from 'services/login.service';
import { camelToKebab, createRedirectUri, emailRegEx, env, getErrorCode, isApp, socialLoginProvider } from 'utils/helper.utils';
import axios, { AxiosResponse } from 'axios';
import MultipleMembersSelect from 'components/MultipleMembersSelect/MultipleMembersSelect.component';
import Layout from 'layouts/Layout.layout';
import Checkbox from 'components/Checkbox/Checkbox.component';
import { Text } from 'components/Text/Text.component';
import { Heading } from '../components/Heading/Heading.component';
import { pushCustomEvents } from 'services/analytics.service';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { loginWithApple } from 'services/loginWithApple.service';
import AppleLogin from 'react-apple-login';
import { IconButton } from 'components/Buttons/IconButton';
import { AppleIcon } from 'components/Icons/icons/Apple.icon';
import { SocialLoginProvider } from 'utils/constants';
import { Icon } from 'components/Icons/Icons.component';

interface IFormInput {
  email: string;
  password: string;
  keepLoggedIn: boolean;
}

const defaultValues = {
  email: '',
  password: '',
  keepLoggedIn: isApp
};

const basicFitDomain = 'basic-fit.com';

const Login: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    resetField,
    formState: { isSubmitting, isValid },
    getFieldState,
    watch,
    setValue,
    setError,
    clearErrors
  } = useForm<IFormInput>({ mode: 'onChange', defaultValues });

  const [isLoading, setIsLoading] = useState(false);
  const [socialLoginErrorMessage, setSocialLoginErrorMessage] = useState('');
  const [hasError, setHasError] = useState('');

  useEffect(() => {
    async function validateAppleLogin() {
      setIsLoading(true);
      try {
        const response = await loginWithApple();
        handleResponse(response);
      } catch (error) {
        if (!axios.isAxiosError(error)) return setHasError(t('somethingWrong'));
        const { email = '' } = JSON.parse(error.response?.config.data ?? '{}');

        setValue('email', email);
        setError('email', { type: 'validate' });
        setSocialLoginErrorMessage(
          `${t('signInWithAppleMessage', 'Make sure you turn on Email Sharing on your Apple ID before trying again.')} ${email}`
        );
      } finally {
        setIsLoading(false);
      }
    }

    if (socialLoginProvider === SocialLoginProvider.APPLE) {
      validateAppleLogin();
    }
  }, [socialLoginProvider]);

  useEffect(() => {
    if (!socialLoginErrorMessage) return clearErrors('email');
    setError('email', { type: 'validate' });
  }, [socialLoginProvider, socialLoginErrorMessage]);

  const [multipleMembers, setMultipleMembers] = useState<MultipleMembership[]>([]);

  const [loginSuccess, setLoginSuccess] = useState(false);
  const [redirectUri, setRedirectUri] = useState('');

  const [featureEnabled, setEnableFeature] = useState(false);

  const emailValues = watch('email');

  useEffect(() => {
    switch (env.REACT_APP_SOCIAL_LOGIN_ENABLED_FOR) {
      case 'ios':
        setEnableFeature(/(iPhone|iPod|iPad).*Safari/i.test(window.navigator.userAgent));
        break;
      case 'all':
        setEnableFeature(true);
        break;
      case basicFitDomain:
        if (!getFieldState('email').error) {
          if (emailValues.endsWith(basicFitDomain)) {
            setEnableFeature(true);
            break;
          } else {
            setEnableFeature(false);
            break;
          }
        }
        break;
      default:
        setEnableFeature(false);
        break;
    }
  }, [emailValues]);

  const redirectOnSuccess = ({ code, accessToken, refreshToken }: Partial<LoginResponse>) => {
    pushCustomEvents({
      eventCategory: 'success',
      eventAction: 'login',
      eventLabel: 'login'
    });

    const redirectUri = createRedirectUri({ code, accessToken, refreshToken });
    setRedirectUri(redirectUri);
    setLoginSuccess(true);

    if (window.Cypress) {
      console.info('MOCK LOGIN: successfulLoginRedirectUri: ' + redirectUri);
      return;
    }
  };

  const handleResponse = (response: AxiosResponse) => {
    resetField('password');

    if (!response) {
      setHasError(t('somethingWrong'));
      return;
    }

    if (window.Cypress) {
      console.info('MOCK LOGIN: handleLoginResponse: ' + response.status);
    }

    if (response?.status == 200) {
      redirectOnSuccess(response.data);
      return;
    }

    if (response?.status == 202) {
      setMultipleMembers(response.data);
      pushCustomEvents({
        eventCategory: 'success',
        eventAction: 'login',
        eventLabel: 'multiple membership'
      });
      return;
    }
  };

  const handleError = (error: unknown) => {
    if (!axios.isAxiosError(error)) return setHasError('somethingWrong');
    const errorCode = getErrorCode(error.response?.data.message ?? '');

    switch (errorCode) {
      case 'ERR_UNAUTHORIZED':
      case 'ERR_INVALID_CREDENTIALS':
      case 'ERR_USER_NAME_EMPTY':
      case 'ERR_PASSWORD_EMPTY':
        setHasError('error401');
        break;
      case 'ERR_NO_ACTIVE_MEMBERSHIP':
        setHasError('membershipExpired');
        break;
      case 'ERR_PEOPLE_NOT_FOUND':
        setHasError('noMembershipFound');
        break;
      case 'ERR_ACCOUNT_BLOCKED':
        setHasError('errorContactSupport');
        break;
      case 'ERR_LOGIN_BLOCKED':
        setHasError('errorEmailTemporaryBlocked');
        break;
      case 'ERR_INTERNAL_SERVER':
        setHasError('somethingWrong');
        break;
      case 'ERR_AUTHORIZATION_CODE_EXPIRED':
        setMultipleMembers([]);
        setHasError('sessionExpired');
        break;
      default:
        setHasError('somethingWrong');
    }
  };

  useEffect(() => {
    if (!hasError) return;

    pushCustomEvents({
      eventCategory: 'error tracking',
      eventLabel: hasError,
      eventAction: 'login'
    });
  }, [hasError]);

  const handleLoginFormSubmit = async ({ email, password, keepLoggedIn }: IFormInput) => {
    if (!email || !password) return;

    setIsLoading(true);
    try {
      const response = await login({ email: email.trim(), password, keepLoggedIn }, { timeout: 30 * 1000 });
      handleResponse(response);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedMembershipHandler = async (code: string) => {
    setIsLoading(true);
    try {
      const response = await authorize(code);
      handleResponse(response);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = (event: React.MouseEvent) => {
    setHasError('');
    setMultipleMembers([]);
    event.preventDefault();
  };

  const isSubmitButtonDisabled = isSubmitting || !isValid;

  const loginWithAppleState = new URLSearchParams(searchParams);
  loginWithAppleState.set('provider', SocialLoginProvider.APPLE);

  const showSocialLoginError = socialLoginErrorMessage && socialLoginProvider === SocialLoginProvider.APPLE;

  return (
    <Layout bannerText={t(multipleMembers.length ? 'multipleMembershipTitle' : 'login')}>
      <form onSubmit={handleSubmit(handleLoginFormSubmit)}>
        {multipleMembers.length ? <MultipleMembersSelect members={multipleMembers} loginSelectedMembership={selectedMembershipHandler} /> : <></>}

        {!multipleMembers.length && (
          <>
            <Controller
              name="email"
              defaultValue={defaultValues.email}
              control={control}
              rules={{ required: 'emailRequired', pattern: { value: emailRegEx, message: 'emailInvalid' } }}
              render={({ field, fieldState }) => (
                <TextField
                  type="email"
                  data-cy={'input-email'}
                  className="mb-xxs"
                  label={t('email')}
                  {...field}
                  autoComplete={'username'}
                  fieldState={fieldState}
                  showHint={false}
                />
              )}
            />

            <Controller
              name="password"
              defaultValue={defaultValues.password}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="password"
                  data-cy={'input-password'}
                  className="mb-xxs"
                  label={t('password')}
                  fieldState={fieldState}
                  showError={false}
                  showHint={false}
                  autoComplete="current-password"
                />
              )}
            />

            <div className="justify-between mt-xs">
              {!isApp && (
                <Controller
                  name="keepLoggedIn"
                  control={control}
                  render={({ field }) => <Checkbox data-cy="keep-logged-in" text={t('keepLoggedIn')} {...field} />}
                />
              )}

              <Link className="inline-block underline mt-xs" data-cy="login-forgot-password-button" to="/reset-password">
                <Text size="s">{t('forgotPassword')}</Text>
              </Link>
            </div>

            {hasError && (
              <>
                <Text size="s" className="text-red my-xs mt-m" data-cy={camelToKebab(hasError)}>
                  {`${t(hasError)} ${showSocialLoginError && socialLoginErrorMessage}`}
                </Text>
                {showSocialLoginError && (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="underline text-orange text-regular-s"
                    href="https://support.apple.com/en-gb/guide/icloud/mm3adb030cbf/icloud"
                  >
                    {t('learnMore', 'Learn more')}
                  </a>
                )}
              </>
            )}

            <Button
              type="submit"
              data-cy={'submit-button'}
              className={`
                    !w-full mt-m
                    ${isSubmitButtonDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                  `}
              disabled={isSubmitButtonDisabled}
            >
              {t('login')}
            </Button>

            <p className="mt-4 text-center">Or</p>

            {featureEnabled && (
              <AppleLogin
                clientId="bfa-ul-test"
                redirectURI={String(env.REACT_APP_SOCIAL_LOGIN_REDIRECT_URI)}
                state={window.btoa(loginWithAppleState.toString())}
                usePopup={false}
                scope="email"
                responseType="code id_token"
                responseMode="form_post"
                render={(renderProps) => (
                  <IconButton
                    onClick={renderProps.onClick}
                    className="text-white bg-black border-black"
                    label={t('signInWithApple', 'Sign in with Apple')}
                    icon={<AppleIcon />}
                  />
                )}
              />
            )}
          </>
        )}
      </form>

      {multipleMembers.length ? (
        <Link onClick={goBack} to="/">
          <Heading size="xs" className="text-center underline mt-m">
            {t('backToLogin')}
          </Heading>
        </Link>
      ) : (
        <Text size="m" className="mt-m">
          {`${t('newToBasicFit')} `}
          <Link data-cy="login-register-link" className="underline text-orange" to="/register">
            {t('activateYourAccount')}
          </Link>
        </Text>
      )}
      <Modal isOpen={isLoading || loginSuccess} disableClose className="w-3/5 max-w-lg">
        {isLoading ? <LoadingModalContent /> : null}
        {loginSuccess ? <RedirectModalContent redirectUri={redirectUri} /> : null}
      </Modal>
    </Layout>
  );
};

type RedirectModalProps = {
  redirectUri: string;
};

const RedirectModalContent = ({ redirectUri }: RedirectModalProps) => {
  const { t } = useTranslation();
  const [showRedirect, setShowRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRedirect(true);
    }, 2000);

    window.location.replace(redirectUri);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-between pt-8 space-y-4">
      <Icon id="thumbs-up" className="!w-[60px] !h-[60px] p-2" />
      <Text size="l" className="pt-4 font-semibold uppercase">
        {t('success')}
      </Text>
      {showRedirect && (
        <Text size="s">
          {t('redirecting')}&nbsp;{t('redirectingMessage')}&nbsp;&nbsp;
          <a href={redirectUri} className="font-bold text-center underline lowercase text-orange">
            {t('continue')}
          </a>
        </Text>
      )}
    </div>
  );
};

const LoadingModalContent = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-between m-auto">
      <img src={process.env.PUBLIC_URL + '/img/loader.png'} alt="Loading..." className="w-[200px]" />
      <Text size="m" className="mt-xxs">
        {t('loading')}
      </Text>
    </div>
  );
};

export default Login;
