import React from 'react';
import { twMerge } from 'tailwind-merge';
import ChevronDown from 'components/Icons/icons/ChevronDown.icon';
import Close from 'components/Icons/icons/Close.icon';
import Check from 'components/Icons/icons/Check.icon';
import ClosedEye from 'components/Icons/icons/ClosedEye.icon';
import OpenEye from 'components/Icons/icons/OpenEye.icon';
import ThumbsUp from 'components/Icons/icons/ThumbsUp.icon';
import FlagES from 'components/Icons/icons/FlagES.icon';
import FlagFR from 'components/Icons/icons/FlagFR.icon';
import FlagNL from 'components/Icons/icons/FlagNL.icon';
import FlagUK from 'components/Icons/icons/FlagUK.icon';
import FlagDE from 'components/Icons/icons/FlagDE.icon';

export interface IconProps {
  className?: string;
  id: typeof icons[number]['id'];
  onClick?: () => void;
}

const icons = [
  { id: 'chevron-down', value: ChevronDown },
  { id: 'close', value: Close },
  { id: 'check', value: Check },
  { id: 'closed-eye', value: ClosedEye },
  { id: 'open-eye', value: OpenEye },
  { id: 'thumbs-up', value: ThumbsUp },
  { id: 'flag-en', value: FlagUK },
  { id: 'flag-es', value: FlagES },
  { id: 'flag-fr', value: FlagFR },
  { id: 'flag-nl', value: FlagNL },
  { id: 'flag-de', value: FlagDE }
];

export const IconIds = () => icons.map((icon) => icon.id);

export const Icon = ({ id, className = '', onClick }: IconProps): JSX.Element => {
  const svg = icons.find((icon) => icon.id === id);
  const IconElement = svg?.value;

  return (
    <>
      {IconElement && (
        <span
          onClick={onClick}
          data-cy={id}
          className={twMerge(`
                        w-[1em] h-[1em] inline-block transform scale-[1.25]
                        ${className}
                    `)}
        >
          <IconElement />
        </span>
      )}
    </>
  );
};
