import React from 'react';
import Select, { CSSObjectWithLabel, GroupBase, Props } from 'react-select';

/**
 * Create custom theme for react-select
 * https://react-select.com/styles
 */
const customStyles = {
  color: '2D2D2D',
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    background: '#ECE9E8',
    borderRadius: 0,
    fontFamily: 'Heading Pro Treble, sans-serif',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '56px',

    paddingLeft: '16px'
  }),
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: 0,
    fontFamily: 'Heading Pro Treble, sans-serif',
    fontWeight: 700,
    fontSize: '18px',
    paddingLeft: '20px',
    lineHeight: '56px'
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#FE7000'
  }),
  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 })
};

/**
 * Forward react-select generics so we can re-use the properties
 * https://react-select.com/typescript#select-generics
 */

export const SelectElement = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, Group>
) => {
  return (
    <Select
      {...props}
      classNamePrefix="react-select"
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#FE7000',
          primary25: '#ECE9E8',
          primary50: '#EFEFEF'
        }
      })}
    />
  );
};
