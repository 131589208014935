import React from 'react';

export default function FlagUK() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
        <path fill="#C4C4C4" d="M0 4h24v16H0z" />
      </mask>
      <g mask="url(#a)">
        <path d="M-1.649 3.913v16.176H25.65V3.913H-1.65Z" fill="#012169" />
        <path d="M-1.649 3.913 25.65 20.089-1.65 3.913Zm27.298 0L-1.65 20.088 25.65 3.913Z" fill="#000" />
        <path
          d="M25.039 21.536 12 13.81l-13.039 7.727-1.22-2.896L8.948 12-2.26 5.36l1.22-2.897 13.04 7.728 13.038-7.728 1.22 2.897L15.053 12l11.206 6.64-1.22 2.896Z"
          fill="#fff"
        />
        <path d="M-1.649 3.913 25.65 20.089-1.65 3.913Zm27.298 0L-1.65 20.088 25.65 3.913Z" fill="#000" />
        <path
          d="M25.241 21.053 12 13.204l-13.241 7.849-.815-1.93L9.966 12-2.056 4.877l.815-1.93L12 10.794l13.241-7.849.815 1.93L14.034 12l12.022 7.123-.815 1.93Z"
          fill="#C8102E"
        />
        <path d="M12 3.913v16.176V3.913ZM-1.65 12H25.65-1.65Z" fill="#000" />
        <path d="M14.275 20.088h-4.55v-5.391H-1.65V9.304H9.725V3.913h4.55v5.391h11.374v5.393H14.275v5.39Z" fill="#fff" />
        <path d="M12 3.913v16.176V3.913ZM-1.65 12H25.65-1.65Z" fill="#000" />
        <path d="M13.365 20.088h-2.73v-6.47H-1.65v-3.235h12.284v-6.47h2.73v6.47h12.284v3.235H13.365v6.47Z" fill="#C8102E" />
      </g>
    </svg>
  );
}
