import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Text/Text.component';

const formatter = new Intl.DateTimeFormat('nl', { minute: '2-digit', second: '2-digit' });

export const CountDown = ({ targetTime }: { targetTime: number }) => {
  const { t } = useTranslation();

  const targetTimeRef = useRef(targetTime);
  const [timeLeft, setTimeLeft] = useState(() => targetTimeRef.current - new Date().getTime());
  const timer = useRef<NodeJS.Timer>();

  useEffect(() => {
    timer.current = setInterval(() => {
      const timeDifference = targetTimeRef.current - Date.now();
      if (timeDifference <= 0) {
        setTimeLeft(0);
        clearInterval(timer.current);
      }
      setTimeLeft(timeDifference);
    }, 1000);

    return () => clearInterval(timer.current);
  }, []);

  const formatTime = (time: number) => {
    if (time <= 0) return formatter.format(0);
    const date = new Date(timeLeft);
    return formatter.format(date);
  };

  return (
    <>
      <Text size="m">{t('resetPasswordTryAgainTip').replace('%%time%%', `${formatTime(timeLeft)}`)}</Text>
    </>
  );
};
