import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from 'pages/Login.page';
import Register from 'pages/Register.page';
import SetPassword from 'pages/SetPassword.page';
import Logout from 'pages/Logout.page';
import InvalidClient from 'components/InvalidClient/InvalidClient.component';
import { validateClientAccess } from 'services/client.service';
import { pushToDataLayer } from 'services/analytics.service';
import { Header } from 'components/Header/Header.component';
import { useTranslation } from 'react-i18next';

export const routes = [
  {
    path: '/reset-password',
    name: 'resetPassword',
    title: 'Reset Password',
    component: <Register type="resetPassword" />
  },
  {
    path: '/register',
    name: 'register',
    title: 'Register',
    component: <Register type="register" />
  },
  {
    path: '/set-password',
    name: 'setPassword',
    title: 'Set Password',
    component: <SetPassword />
  },
  {
    path: '/logout',
    name: 'logout',
    title: 'Logout',
    component: <Logout />
  },
  {
    path: '/',
    name: 'default',
    title: 'Login',
    component: <Login />
  }
];

const App = () => {
  const [isValidClient, setIsValidClient] = useState(true);
  const { t } = useTranslation();

  const cookieDisabledTitle = t('cookieDisabledMessageTitle', { defaultValue: 'Please enable cookies.' });
  const cookieDisabledDescription = t('cookieDisabledMessageDescription', {
    defaultValue: 'Please enable first party cookies in your browser/devices settings. These cookies are needed to run this website.'
  });

  if (!window.navigator.cookieEnabled) {
    return (
      <>
        <Header>
          <p>{cookieDisabledTitle}</p>
        </Header>
        <p className="container mx-auto mt-8 bg-white mb-[72px] text-red sm:mb-[124px]">{cookieDisabledDescription}</p>
      </>
    );
  }

  useEffect(() => {
    async function verifyClientAccess() {
      const response = await validateClientAccess().catch((err) => {
        console.error(err);
        return err.response;
      });

      const isValidClient = response?.status === 200;

      setIsValidClient(isValidClient);

      if (isValidClient) {
        pushToDataLayer({
          event: 'referred domain',
          'referred domain': response.data.clientName
        });
      }
    }

    verifyClientAccess();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          {routes.map(({ path, name, component }, index) => (
            <Route path={path} key={`${name}-${index}`} element={component} />
          ))}
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </Router>
      {!isValidClient && <InvalidClient />}
    </>
  );
};

export default App;
