import React, { useEffect, useState } from 'react';
import TextField from 'components/TextField/TextField.component';
import { Button } from 'components/Buttons/Button.component';
import { Link, useNavigate } from 'react-router-dom';
import { changePassword } from '../services/password.service';
import Popup from 'components/Popup/Popup.component';
import { AxiosResponse } from 'axios';
import Layout from 'layouts/Layout.layout';
import { Text } from 'components/Text/Text.component';
import { Heading } from '../components/Heading/Heading.component';
import { pushCustomEvents } from 'services/analytics.service';
import { camelToKebab, getErrorCode, isApp, passwordResetToken, safeJsonParse } from 'utils/helper.utils';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

interface IFormInput {
  password: string;
  confirmPassword: string;
}

const defaultValues = {
  password: '',
  confirmPassword: ''
};

const AppSuccessMessage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text size="m" className="mb-s" data-cy={camelToKebab('passwordSuccessMessageApp')}>
        {t('passwordSuccessMessageApp')}
      </Text>
      <Button className="w-full" onClick={() => window.location.assign('https://basicfit.page.link/app')} data-cy={camelToKebab('goToApp')}>
        {t('goToApp')}
      </Button>
      <Text size="m" className="mt-m" data-cy={camelToKebab('goToMyBasicFit')}>
        {`${t('goToMyBasicFit')} `}
        <Link data-cy="login-link" className="text-orange underline" to="/">
          {t('login')}
        </Link>
      </Text>
    </>
  );
};

const SuccessMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Text size="m" className="mb-s" data-cy={camelToKebab('passwordHasBeenSet')}>
        {t('passwordHasBeenSet')}
      </Text>
      <Button className="w-full" onClick={() => navigate('/login')} data-cy={camelToKebab('goToLogin')}>
        {t('goToLogin')}
      </Button>
    </>
  );
};

const SetPassword = () => {
  const [showPopup, setShowPopup] = useState('');
  const [hasError, setHasError] = useState('');
  const [linkExpired, setLinkExpired] = useState<boolean | null>(null);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid }
  } = useForm<IFormInput>({ mode: 'onChange', defaultValues });

  const password = watch('password');

  useEffect(() => {
    const [_header, payload] = passwordResetToken.split('.');
    const tokenPayload = safeJsonParse(window.atob(payload || ''));
    if (!tokenPayload || Date.now() >= (tokenPayload?.exp as number) * 1000) {
      setLinkExpired(true);
      setHasError('linkExpired');
    } else {
      setLinkExpired(false);
    }
  }, []);

  useEffect(() => {
    if (!hasError) return;

    pushCustomEvents({
      eventCategory: 'error tracking',
      eventLabel: hasError,
      eventAction: 'set password'
    });
  }, [hasError]);

  const handleResponse = (response: AxiosResponse) => {
    setShowPopup('');
    if (response.status === 200) {
      setSuccess(true);
      pushCustomEvents({
        eventCategory: 'success',
        eventAction: 'password',
        eventLabel: 'set password'
      });
      return;
    }

    const errorCode = getErrorCode(response.data.message);

    switch (errorCode) {
      case 'ERR_RESET_PASSWORD_TOKEN_EXPIRED':
      case 'ERR_BOARDING_ALREADY_COMPLETED':
      case 'ERR_INVALID_CREDENTIALS_ID':
        setLinkExpired(true);
        setHasError('linkExpired');
        break;
      case 'ERR_PASSWORD_CONFIRMATION_NOT_MATCH':
        setHasError('passwordNotValid');
        break;
      default:
        setHasError('somethingWrong');
    }
  };

  const handleFormSubmit = async ({ password, confirmPassword }: IFormInput) => {
    setShowPopup('loader');
    const response = await changePassword(password, confirmPassword).catch((err) => err.response);
    handleResponse(response);
  };

  return (
    <Layout bannerText={t(success ? 'success' : 'setPassword')}>
      {showPopup && <Popup type={showPopup} />}

      {success && (isApp ? <AppSuccessMessage /> : <SuccessMessage />)}

      {!success && linkExpired !== null && (
        <>
          {!linkExpired && (
            <>
              <Text size="m" className="mb-s" data-cy={camelToKebab('setPasswordDescription')}>
                {t('setPasswordDescription')}
              </Text>

              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <>
                  <Controller
                    name="password"
                    defaultValue={defaultValues.password}
                    control={control}
                    rules={{ required: 'passwordNotValid', minLength: { value: 8, message: 'passwordTooShort' } }}
                    render={({ field, fieldState }) => (
                      <TextField
                        className="mb-xxs"
                        type="password"
                        label={t('password')}
                        {...field}
                        fieldState={fieldState}
                        data-cy={'input-password'}
                      />
                    )}
                  />

                  <Controller
                    name="confirmPassword"
                    defaultValue={defaultValues.confirmPassword}
                    control={control}
                    rules={{
                      required: 'passwordNotValid',
                      validate: (value) => (value === password ? true : 'confirmPasswordError')
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        className="mb-xxs"
                        type="password"
                        label={t('confirmPassword')}
                        {...field}
                        fieldState={fieldState}
                        data-cy={'input-confirm-password'}
                        showHint={false}
                      />
                    )}
                  />

                  {hasError && (
                    <Text size="s" className="text-red my-xs" data-cy={camelToKebab(hasError)}>
                      {t(hasError)}
                    </Text>
                  )}

                  <Button
                    type="submit"
                    disabled={!isValid}
                    data-cy={'submit-button'}
                    className={`
                      !w-full
                      ${showPopup === 'loader' ? 'cursor-progress' : 'cursor-pointer'}
                      ${isValid ? '' : 'cursor-not-allowed'}
                    `}
                  >
                    {t('setPassword')}
                  </Button>
                </>
              </form>
            </>
          )}

          {linkExpired && (
            <>
              {hasError && (
                <Text size="s" className="text-red my-xs" data-cy={camelToKebab(hasError)}>
                  {t(hasError)}
                </Text>
              )}

              <Button
                data-cy={'reset-password-button'}
                onClick={() => navigate('/reset-password')}
                className={`
                  !w-full cursor-pointer
                `}
              >
                {t('resetPassword')}
              </Button>
            </>
          )}

          <Link to="/" data-cy={'back-to-login'}>
            <Heading size="xs" className="text-center mt-m underline">
              {t('backToLogin')}
            </Heading>
          </Link>
        </>
      )}
    </Layout>
  );
};

export default SetPassword;
