import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modals/Modal.component';
import { Text } from 'components/Text/Text.component';

const InvalidClient = () => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={true} disableClose={true}>
      <Text size="s" className="pt-s sm:pt-xxl text-center" data-cy="client-credentials-error-message">
        {t('clientCredentialsNotValid')}
      </Text>
    </Modal>
  );
};

export default InvalidClient;
