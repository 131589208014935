import $http from 'services/http.service';
import i18n from 'services/i18n.service';
import { clientId, passwordResetToken, redirectUri, responseType, env, isApp } from 'utils/helper.utils';

// sends out password reset email on success
export const resetPassword = async (params: { email: string; register: boolean }) =>
  $http({
    url: `/reset-password`,
    method: 'post',
    data: { ...params, locale: i18n.language, app: isApp },
    headers: {
      'client-id': isApp ? (env.REACT_APP_DEFAULT_CLIENT_ID as string) : clientId,
      'redirect-uri': isApp ? (env.REACT_APP_DEFAULT_REDIRECT_URL as string) : redirectUri,
      'response-type': isApp ? 'code' : responseType
    }
  });

export const changePassword = async (password: string, passwordConfirm: string) =>
  $http({
    url: `/set-password`,
    method: 'post',
    headers: {
      'client-id': clientId,
      'redirect-uri': redirectUri,
      'reset-token': passwordResetToken
    },
    data: { password, passwordConfirm }
  });
