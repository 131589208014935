import React from 'react';
import { PlainCard } from 'components/Cards/PlainCard.component';
import { useState } from 'react';
import { Button } from 'components/Buttons/Button.component';
import { Heading } from 'components/Heading/Heading.component';
import { Text } from 'components/Text/Text.component';
import { useTranslation } from 'react-i18next';

const MultipleMembersSelect = ({ members, loginSelectedMembership }: MultiMemberSelectProps) => {
  const [selected, setSelected] = useState<MultipleMembership>(members[0]);

  const { t } = useTranslation();

  return (
    <>
      <Text size="m" className="mb-s" data-cy={`multiple-membership-description`}>
        {t('multipleMembershipsDescription').replace('%%firstname%%', members[0]?.name || 'member')}
      </Text>

      {members.map((member) => {
        return (
          <PlainCard
            key={member.code}
            onClick={() => {
              setSelected(member);
            }}
            leftBarColor={selected.code === member.code ? 'border-orange' : 'border-jersey-grey'}
            data-cy="mutliple-memberships-card"
          >
            <>
              <Heading size="xxs" className="mb-xxs normal-case">
                {member.name}
              </Heading>
              <Text size="m" className="mb-xxs">
                {member.membershipName}
              </Text>
              <div className="flex items-end sm:items-center">
                <Heading size="xxs" className="normal-case">
                  {t('membershipNumber')}:&nbsp;
                </Heading>
                <Text size="m">{member.membershipNumber}</Text>
              </div>
            </>
          </PlainCard>
        );
      })}
      <Button
        onClick={(event) => {
          event.preventDefault();
          loginSelectedMembership(selected.code);
        }}
        className={`mt-[16px] w-full`}
        data-cy="submit-button"
      >
        {t('login')}
      </Button>
    </>
  );
};

export default MultipleMembersSelect;
