import React, { HTMLAttributes } from 'react';

interface IPlainCard extends HTMLAttributes<HTMLDivElement> {
  leftBarColor: string;
  onClick?: () => void;
  children: JSX.Element;
}

export const PlainCard = ({ leftBarColor, children, onClick, ...rest }: IPlainCard): JSX.Element => {
  return (
    <div className={`mb-[8px] p-[16px] bg-jersey-grey border-l-[10px] ${leftBarColor} ${onClick && 'cursor-pointer'}`} onClick={onClick} {...rest}>
      {children}
    </div>
  );
};
