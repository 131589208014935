import React from 'react';

const CheckIcon = () => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14918 7.3678L0.126313 4.16396C-0.0421042 3.98769 -0.0421042 3.70078 0.126313 3.52263L0.736931 2.88224C0.905348 2.70596 1.17913 2.70596 1.34755 2.88224L3.45492 5.12502L8.15245 0.132204C8.32087 -0.044068 8.59465 -0.044068 8.76307 0.132204L9.37369 0.773534C9.5421 0.949806 9.5421 1.23765 9.37369 1.41299L3.7598 7.3678C3.59138 7.54407 3.31759 7.54407 3.14918 7.3678Z"
      fill="#FE7000"
    />
  </svg>
);

export default CheckIcon;
