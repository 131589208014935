import React from 'react';

export default function ChevronDownIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="origin-[center] transition-[transform] duration-[300ms] group-hover:translate-y-[2px]"
    >
      <path d="M2 2L9 8.96643L16 2" stroke="currentColor" strokeWidth="3" />
    </svg>
  );
}
