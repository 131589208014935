import React, { HTMLAttributes } from 'react';
import { Translation } from 'react-i18next';

type State = {
  hasError: boolean;
};

type ErrorBoundaryProps = HTMLAttributes<HTMLDivElement>;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Translation>{(t) => <h1>{t('somethingWrong')}</h1>}</Translation>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
