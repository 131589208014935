import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import TextField from 'components/TextField/TextField.component';
import { Button } from 'components/Buttons/Button.component';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../services/password.service';
import Popup from 'components/Popup/Popup.component';
import Layout from 'layouts/Layout.layout';
import { Heading } from 'components/Heading/Heading.component';
import { Text } from 'components/Text/Text.component';
import { pushCustomEvents } from 'services/analytics.service';
import { camelToKebab, createRedirectUri, emailRegEx, env, getErrorCode, isApp } from 'utils/helper.utils';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { setResetPasswordCookieForEmail, getResetPasswordTimeout } from 'services/cookie.service';
import { CheckItem } from 'components/CheckItem/CheckItem.component';
import { CountDown } from 'components/CountDown/CountDown.component';
import axios from 'axios';

type IRegister = HTMLAttributes<HTMLDivElement> & { type: string };

interface IFormInput {
  email: string;
}

const Register = ({ type }: IRegister) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid },
    getValues
  } = useForm<IFormInput>({ mode: 'onChange', defaultValues: { email: '' } });

  const [errorKey, setErrorKey] = useState('');
  const [showPopup, setShowPopup] = useState('');
  const [registered, setRegistered] = useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [titleText, setTitleText] = useState(() => t(type === 'register' ? 'registerNewAccount' : 'resetPassword'));

  const redirectTimeout = useRef(0);
  useEffect(() => () => clearTimeout(redirectTimeout.current));

  useEffect(() => {
    if (!errors.email) return;

    pushCustomEvents({
      eventCategory: 'error tracking',
      eventLabel: errorKey,
      eventAction: `${type === 'register' ? 'register' : 'reset password'}`
    });
  }, [errors.email]);

  const handleErrorMessage = (key: string): void => {
    setError('email', { message: t(key) });
    setErrorKey(key);
  };

  const handleWebview = () => {
    const redirectUri = createRedirectUri({
      error: 'invalid_request',
      errorDescription: `${type === 'register' ? 'Register Account!' : 'Reset password!'}`
    });

    redirectTimeout.current = window.setTimeout(() => {
      window.location.replace(redirectUri);
    }, +(env.REACT_APP_BFA_PASSWORD_RESET_TIMEOUT || 30000));
  };

  const handleError = (error: unknown) => {
    const message = axios.isAxiosError(error) ? error.response?.data?.message || '' : '';

    const errorCode = getErrorCode(message);

    switch (errorCode) {
      case 'ERR_UNAUTHORIZED':
      case 'ERR_BOARDING_ALREADY_COMPLETED':
      case 'ERR_BOARDING_NOT_COMPLETED':
      case 'ERR_BOARDING_NOT_STARTED':
        handleErrorMessage('error401');
        break;
      case 'ERR_PEOPLE_NOT_FOUND':
        handleErrorMessage('noMembershipFound');
        break;
      case 'ERR_NO_ACTIVE_MEMBERSHIP':
        handleErrorMessage('membershipExpired');
        break;
      case 'ERR_REGISTER_FORBIDDEN':
        handleErrorMessage('emailAlreadyRegisterd');
        break;
      case 'ERR_INTERNAL_SERVER':
        // TODO: specify something on OUR side went wrong
        handleErrorMessage('somethingWrong');
        break;
      default:
        handleErrorMessage('somethingWrong');
    }
  };

  const handleFormSubmit = async ({ email }: IFormInput) => {
    if (showPopup === 'loader') return;

    if (getResetPasswordTimeout(email) > 0) {
      setEmailHasBeenSent(true);
      setRegistered(true);
      return;
    }

    setShowPopup('loader');
    try {
      const response = await resetPassword({ email: email.trim(), register: type === 'register' });
      if (response.status !== 200) return handleError(response);

      setRegistered(true);
      setTitleText(t('welcome', 'welcome'));
      setShowPopup('success');
      pushCustomEvents({
        eventCategory: 'success',
        eventAction: `${type === 'register' ? 'register' : 'reset password'}`,
        eventLabel: `${type === 'register' ? 'register' : 'reset password'}`
      });

      if (isApp && !window.Cypress) handleWebview();

      setResetPasswordCookieForEmail(email);
    } catch (error) {
      handleError(error);
    } finally {
      setShowPopup('');
    }
  };

  const isSubmitButtonDisabled = !!errors.email || !isValid;

  return (
    <Layout bannerText={titleText}>
      {showPopup && <Popup type={showPopup} />}

      {registered && (
        <div className="flex flex-col w-full text-justify">
          {type === 'register' ? (
            <Text size="m" className="mb-s" data-cy={camelToKebab(type === 'register' ? 'registrationSuccessMessage' : 'passwordResetEmailSent')}>
              {t('registrationSuccessMessage')}
            </Text>
          ) : (
            <div>
              <Text size="m" className="mt-s" data-cy={camelToKebab('passwordResetEmailSent')}>
                {t('passwordResetEmailSent')}
                <br />
                <span className="font-bold text-orange">{getValues('email')}</span>
              </Text>
              <br />
              {emailHasBeenSent ? (
                <div className="flex flex-col w-full text-justify bg-jersey-grey bg-opacity-30 border border-jersey-grey border-opacity-50 p-3">
                  <Text size="m" className="mt-xxs" data-cy={camelToKebab('resetPasswordTipsHeaderRepeat')}>
                    {t('resetPasswordTipsHeaderRepeat')}
                  </Text>
                  <CountDown targetTime={getResetPasswordTimeout(getValues('email'))} />
                </div>
              ) : null}
              <br />
              <Text size="m" className="font-bold mb-xs" data-cy={camelToKebab('resetPasswordTipsHeader')}>
                {t('resetPasswordTipsHeader')}
              </Text>
              {t('resetPasswordHelpTips')
                .split('\\n')
                .map((item, i) => (
                  <CheckItem key={i} text={item}></CheckItem>
                ))}
            </div>
          )}
          <div className="flex flex-col justify-center space-y-m mt-s">
            <Button onClick={() => navigate('/')} data-cy={camelToKebab('goToLogin')} className="w-full">
              {t('goToLogin')}
            </Button>
            <button type="button" aria-label={t('backToLogin')} onClick={() => setRegistered(false)} data-cy={'back-button'}>
              <Heading size="xs" className="text-center underline">
                {t('backToLogin')}
              </Heading>
            </button>
          </div>
        </div>
      )}

      {!registered && (
        <>
          <Text size="m" className="mb-s" data-cy={camelToKebab(type === 'register' ? 'registerNewAccountDescription' : 'resetPasswordDescription')}>
            {t(type === 'register' ? 'registerNewAccountDescription' : 'resetPasswordDescription')}
          </Text>

          <form onSubmit={handleSubmit(handleFormSubmit)} data-cy={type === 'register' ? 'register-from' : 'reset-password-form'}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{ required: 'emailRequired', pattern: { value: emailRegEx, message: 'emailInvalid' } }}
              render={({ field, fieldState }) => (
                <TextField data-cy={'input-email'} className="mb-xxs" label={t('email')} {...field} fieldState={fieldState} showHint={false} />
              )}
            />

            <Button
              type="submit"
              data-cy={'submit-button'}
              disabled={isSubmitButtonDisabled}
              className={`!w-full mt-m ${isSubmitting ? 'cursor-progress' : isSubmitButtonDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
              <span data-cy={camelToKebab(type === 'register' ? 'register' : 'sendEmail')}>{t(type === 'register' ? 'register' : 'sendEmail')}</span>
            </Button>
          </form>

          <div className="flex justify-center mt-m">
            <button
              type="button"
              aria-label={t('backToLogin')}
              onClick={() => (document.referrer === '' ? navigate('/') : navigate(-1))}
              data-cy={'back-button'}
            >
              <Heading size="xs" className="text-center underline">
                {t('backToLogin')}
              </Heading>
            </button>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Register;
