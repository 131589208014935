import React from 'react';
import { components, SingleValueProps } from 'react-select';
import { Icon } from 'components/Icons/Icons.component';

const { Option, SingleValue } = components;

export type LanguageLabelProps = {
  value: string;
  label: string;
  language: string;
};

export const LanguageLabel = ({ language, label }: { language: string; label: string }) => {
  return (
    <>
      <Icon id={`flag-${language}`} className="mr-xs scale-100 w-8 h-8 translate-y-1" />
      {label}
    </>
  );
};

// eslint-disable-next-line
export const OptionWithLanguage = (props: any) => (
  <Option {...props}>
    <LanguageLabel label={props.data.label} language={props.data.language} />
  </Option>
);

// eslint-disable-next-line
export const SelectedWithLanguage = ({ children, ...props }: SingleValueProps<LanguageLabelProps>) => (
  <SingleValue {...props}>
    <LanguageLabel label={props.data.label} language={props.data.language} />
  </SingleValue>
);
