import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import $http from 'services/http.service';
import { locale as queryLocale } from 'utils/helper.utils';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  supportedLngs: ['en', 'nl', 'fr', 'es', 'de'],
  lng: 'en',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false
  }
});

const supportedLocales = [
  { locale: 'nl-NL', language: 'nl' },
  { locale: 'fr-FR', language: 'fr' },
  { locale: 'de-DE', language: 'de' },
  { locale: 'es-ES', language: 'es' },
  { locale: 'en-GB', language: 'en' }
];

export const getLocale = (locale?: string) => {
  locale = (locale || queryLocale).toLowerCase();
  const [language] = locale.split('-');
  const supportedLocale = supportedLocales.find(({ language: supportedLanguage }) => supportedLanguage === language);
  return supportedLocale && language !== 'en' ? supportedLocale.locale : 'en-GB';
};

const fetchTranslations = async (locale: string) => {
  const { status, data } = await $http({
    method: 'GET',
    baseURL: process.env.PUBLIC_URL,
    url: `/locales/${getLocale(locale)}.json`,
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch((err) => {
    console.error(`Unable to fetch translations for locale ${locale} - error is ${err}`);
    return err.response;
  });

  return status === 200 ? data : null;
};

export const getTranslations = async (locale?: string) => {
  locale = (locale || getLocale()).toLowerCase();

  return await fetchTranslations(locale);
};

const setTranslationsToI18n = (translation: unknown, language: string) => {
  if (translation) {
    i18n.addResources(language, 'translation', translation);
    i18n.changeLanguage(language);
  }
};

export const init = async () => {
  const language = getLocale()?.split('-')[0];
  const translation = await getTranslations();
  setTranslationsToI18n(translation, language);
};

export const changeLanguage = async (selectedLocale: string) => {
  const language = selectedLocale.split('-')[0];
  if (!i18n.hasResourceBundle(language, 'translation')) {
    const translation = await getTranslations(selectedLocale);
    i18n.addResources(language, 'translation', translation);
  }
  i18n.changeLanguage(language);
};

export default i18n;
