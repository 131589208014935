export const pushToDataLayer = (obj: DataLayerObject): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const pushPageView = ({ title, pathname }: IPushPageView): void => {
  if (window.Cypress) return;
  pushToDataLayer({
    event: 'virtualPageView',
    virtualPagePath: pathname,
    virtualPageTitle: title
  });
};

export const pushCustomEvents = ({ eventCategory, eventAction, eventLabel }: IPushCustomEvents): void => {
  if (window.Cypress) return;
  pushToDataLayer({
    event: eventCategory === 'error tracking' ? 'error.tracking' : 'eventPush',
    eventCategory,
    eventAction: eventAction,
    eventLabel,
    eventValue: undefined,
    eventNonInteraction: true
  });
};
