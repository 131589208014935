import React from 'react';

type TextProps = {
  className?: string;
  children: React.ReactNode;
  size: 'l' | 'm' | 's';
};

/**
 * Tailwind doesn't support dynamic classnames
 * Create a helper function that returns the class as whole string
 * https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
export const getTextSize = (size: string) => {
  switch (size) {
    case 'l':
      return 'text-regular-s sm:text-regular-l';
    case 'm':
      return 'text-regular-s sm:text-regular-m';
    case 's':
      return 'text-regular-s';
  }
};

export const Text = ({ className = '', children, size, ...rest }: TextProps) => {
  return React.createElement(
    'p',
    {
      className: `${getTextSize(size)} font-heading-double m-0 ${className}`,
      ...rest
    },
    children
  );
};
