import React, { ButtonHTMLAttributes, FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface IconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
  label: string;
}

export const IconButton: FC<IconProps> = ({ className, icon, label, ...rest }) => {
  return (
    <button
      className={twMerge(
        `lbf-button
        text-[1rem] text-center
        h-[56px] font-bold font-heading-treble leading-[1.2]
        transition-all duration-300 
        mt-4 w-full 
      `,
        className
      )}
      {...rest}
    >
      <div className="inline-flex justify-center items-center">
        {icon}
        <span className="self-end ml-2">{label}</span>
      </div>
    </button>
  );
};
