import * as React from 'react';
import { Icon } from 'components/Icons/Icons.component';
import { Text } from 'components/Text/Text.component';

export const CheckItem = ({ text }: { text: React.ReactNode }) => {
  return (
    <div className="flex items-center">
      <Icon id="check" className="w-2.5 h-2" />
      <Text size="m" className="ml-xs first-letter:capitalize">
        {text}
      </Text>
    </div>
  );
};
