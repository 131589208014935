export const env = { ...process.env, ...window.REACT_VARIABLES };

export const searchParams = new URLSearchParams(location.search.substring(1));

export const redirectUri = searchParams.get('redirect_uri') || (env.REACT_APP_DEFAULT_REDIRECT_URL as string) || 'https://my.basic-fit.com/sso';

// when password reset email is sent client_id will be set in the reset password URL as ref
export const clientId = searchParams.get('client_id') || searchParams.get('ref') || (env.REACT_APP_DEFAULT_CLIENT_ID as string);

export const responseType = searchParams.get('response_type') || 'token';

export const codeChallenge = searchParams.get('code_challenge') || '';

export const codeChallengeMethod = searchParams.get('code_challenge_method') || '';

export const state = searchParams.get('state') || '';

export const disableRegister = searchParams.get('disable_register') === 'true';

export const passwordResetToken = searchParams.get('token') || '';

export const locale = searchParams.get('locale') || navigator.language;

export const isApp = searchParams.get('app') === 'true';

export const loginWithAppleCode = searchParams.get('code') || '';

export const loginWithAppleIdToken = searchParams.get('id_token') || '';

export const socialLoginProvider = searchParams.get('provider') || '';

// bfa-trace-id is used to track the request in the logs
// This is kebab cased for consistency with the headers
// because in other places this is used as a header
export const bfaTraceId = searchParams.get('bfa-trace-id') || '';

export const createRedirectUri = ({ code, accessToken, refreshToken, error, errorDescription }: redirectUriParams) => {
  const url = new URL(redirectUri);

  if (code) {
    url.searchParams.set('code', code);
  }

  if (accessToken) {
    url.searchParams.set('access_token', accessToken);
  }

  if (refreshToken) {
    url.searchParams.set('refresh_token', refreshToken);
  }

  if (error) {
    url.searchParams.set('error', error);
  }

  if (errorDescription) {
    url.searchParams.set('error_description', errorDescription);
  }

  if (state) {
    url.searchParams.set('state', state);
  }

  return url.href;
};

export const getErrorCode = (message: string) => {
  if (message.includes('ERR_')) {
    const [errorCode] = message.split(' ');
    return errorCode;
  }

  return 0;
};

export const safeJsonParse = (data: string) => {
  let parsedData: Record<string, unknown> | null = null;
  try {
    parsedData = JSON.parse(data);
  } catch (err) {
    console.error(`Unable to parse JSON : ${data}`);
  }
  return parsedData;
};

export const emailRegEx =
  // spec compliant email regex: https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
  // This regex extends the spec compliant one to allow for whitespace before and after the email address: ^\s* and \s*$
  // Usually this is not needed as the browser will trim the whitespace before and after the email address on validation.
  // But we are doing custom validation and need to trim the whitespace ourselves.
  // eslint-disable-next-line no-useless-escape
  /^\s*^[^\.][a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}\s*$/;

export const camelToKebab = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
