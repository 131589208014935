import React, { LegacyRef } from 'react';
import { Icon } from 'components/Icons/Icons.component';
import { Text } from 'components/Text/Text.component';

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  text?: string;
  value?: unknown;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className = '', text = '', value, ...rest }: CheckboxProps, ref): JSX.Element => {
    return (
      <label className={`lbf-checkbox flex items-center group cursor-pointer ${className}`}>
        <input ref={ref as LegacyRef<HTMLInputElement>} type="checkbox" className={`lbf-checkbox__input hidden`} {...rest} />
        <span
          className={`lbf-checkbox__span w-[16px] h-[16px] border border-solid border-anthracite flex justify-center items-center
        ${value ? '' : 'group-hover:border-light-grey'}
      `}
        >
          <Icon
            id="check"
            className={`
          w-[10px] h-[8px]
          ${value ? '' : 'hidden'}
        `}
          />
        </span>
        {text && (
          <Text size="m" className="ml-xxs">
            {text}
          </Text>
        )}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
