import React, { useEffect, useState } from 'react';
import { LanguageSwitch } from 'layouts/components/LanguageSwitch.component';
import { Icon } from 'components/Icons/Icons.component';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const [openLanguageSelector, setLanguageSelectorStatus] = useState(false);
  const [helpUrl, setHelpUrl] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const locale = i18n.language === 'en' ? 'en-nl' : `${i18n.language}-${i18n.language}`;
    setHelpUrl(`https://www.basic-fit.com/${locale}/faq`);
  }, [i18n.language]);

  return (
    <>
      <footer className="fixed bottom-0 w-full bg-gradient-anthracite">
        <div className="w-full flex flex-row items-center h-[60px] text-[16px] text-white justify-between">
          <div className="ml-l">
            <a href={helpUrl} target="_blank" rel="noreferrer">
              {t('needHelp')}
            </a>
          </div>
          <div
            className="flex items-center font-bold cursor-pointer mr-l"
            onClick={() => setLanguageSelectorStatus(true)}
            data-cy="language-selector"
          >
            <span data-cy="selected-language" className="pr-1">
              {i18n.language.toUpperCase()}
            </span>
            <Icon id="chevron-down" />
          </div>
        </div>
      </footer>
      <div className="max-w-sm">
        <LanguageSwitch isOpen={openLanguageSelector} onClose={() => setLanguageSelectorStatus(false)} />
      </div>
    </>
  );
};
